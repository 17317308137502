.customer-convo {
    display: flex;
    height: 100vh;
}

.convo-list {
    width: 35%;
    border-right: 1px solid #ddd;
    padding: 10px;
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: auto;
    background-color: #fff;
}

.convo-item {
    display: flex;
    align-items: center;
    padding: 5px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
}

.convo-item.active {
    background-color: #f0f0f0;
}

.convo-img {
    width: 40px;
    height: 40px;
    border-radius: 25%;
    margin-right: 10px;
}
.convo-img-wrapper {
    position: relative;
}

/* .convo-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
} */

.convo-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    border: 2px solid #fff;
}
.convo-details {
    flex: 1;
}

.convo-name {
    font-weight: 600;
    text-align: left;
    color: #1b3a57;
}

.convo-message {
    font-size: 12px;
    color: #555;
    text-align: start;
}

.convo-time {
    font-size: 10px;
    color: #999;
    text-align: right;
}

.convo-messages {
    width: 65%;
    padding: 20px 20px 0px 20px;
    overflow-y: auto;
}

.convo-header {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;
    position: sticky;
    overflow-y: auto;
    /* height: 100vh; */
}

.convo-header-img {
    width: 50px;
    height: 50px;
    border-radius: 25%;
}

.convo-header-details {
    flex: 1;
    margin-left: 10px;
}

.convo-header-name {
    font-weight: bold;
}

.convo-header-status {
    color: #888;
}

.convo-header-actions {
    display: flex;
    gap: 10px;
}

.convo-header-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.message {
    margin-bottom: 20px;
    overflow-y: auto;
}
.message.from-you{
    justify-content: flex-end;
    text-align: right;
    overflow-y: auto;
}
.message.from-them{
    justify-content: flex-start;
    text-align: left;
}
.message.from-you .message-text {
    background-color: #d3d3d3;
    overflow-y: auto;
}

.message.from-them .message-text {
    background-color: #1b3a57;
    color: #fff;
}

.message-text {
    padding: 10px;
    border-radius: 10px;
    max-width: 60%;
    display: inline-block;
}
.stock-price{
    gap: 109px;
}
.message-time {
    color: #888;
    font-size: 12px;
    margin-top: 5px;
}

.product-info-chat {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f1f3f9;
    width: 325px;
}
.product-info-chat img{
    background-color: #fff;
    border-radius: 5px;
    border-color: #555;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
}
.chat-product{
    width: 50px;
    height: 50px;
}
.head-chat-time{
    background-color: #FAF5FA;
    border-radius: 5px;
    padding: 5px 10px;
}
.no-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
}
.convo-list::-webkit-scrollbar {
    width: 4px;
  }
  
  .convo-list::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  
  .convo-list::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
@media (min-width: 1400px) {
    .convo-list{
        width: 30%;
    }
    .convo-messages{
        width: 70%;
    }
    .message-text {
        max-width: 50%;
    }
    .product-info-chat {
        width: 380px;
    }
    .stock-price{
        gap: 160px;
    }
}

.date-separator {
    margin-bottom: 16px;
}

.message-chat:where(.css-dev-only-do-not-override-m4timi).ant-modal .ant-modal-content{
    width: 400px;
    margin: auto;
}
.message-chat:where(.css-dev-only-do-not-override-m4timi).ant-modal .ant-modal-footer{
    display: none;
}
:where(.css-dev-only-do-not-override-m4timi).ant-input-outlined:focus,
:where(.css-dev-only-do-not-override-m4timi).ant-input-outlined:hover,
:where(.css-dev-only-do-not-override-m4timi).ant-input-outlined:focus-within{
    border-color: #1b3a57 !important;
    background-color: #ffffff;
    box-shadow: #1b3a57 !important;
}
.customer-support-header{
    display: flex;
    justify-content: space-between;

    
}





@media (max-width: 768px) {
    .customer-convo {
      flex-direction: column;
      height: 87vh;
    }
  
    .convo-list {
      width: 100%;
      max-height: 100vh;
    }
  
    .convo-messages {
      width: 100%;
      flex: 1;
      padding-top: 0px;
    }
  
    .header-search-bar {
      width: 100%;
    }
    .exit-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        font-size: 24px;
        cursor: pointer;
      }
      .convo-messages-header-right{
        display: none;
      }
      .convo-messages-header-left{
        margin-left: 40px;
      }
      .convo-header-name-time{
        margin-left: 20px;
      }
      .exit-icon {
        left: 0px;
      }
      .customer-support-header{
        display: none;
      }
      :where(.css-dev-only-do-not-override-m4timi).ant-input-affix-wrapper{
        position: sticky;
        bottom: 20px;
      }
      .convo-header{
        position: sticky;
        top: 0px;
        background-color: #fff;
      }
      .ant-modal-content{
        width: 100%;
      }
  }
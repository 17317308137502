.navbar-header {
  background: #ffffff;
  padding: 20px 24px;
  margin-left: 275px;
  /* border-bottom: 1px solid #d4d4e2; */
  position: sticky;
  top: 0px;
  z-index: 999;
}

.search-container {
  position: relative;
}

.search-icon {
  position: absolute;
  top: 42%;
  transform: translateY(-50%);
  left: 0;
  display: flex;
  align-items: center;
  padding-left: 8px;
  font-size: 18px;
}

.search-input {
  border: 1px solid #fff;
  padding: 8px 36px;
  background: transparent;
  border-radius: 8px;
  color: #8592AD;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.notification-button {
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #1c1c1c;
}

.notification-badge {
  position: absolute;
  top: -10px;
  right: 0;
  background-color: #ce1b28;
  color: #fff;
  border-radius: 50%;
  padding: 3px 6px;
  font-size: 10px;
}

.user-info {
  padding: 0 0 0 12px;
  cursor: pointer;
}

.user-info span {
  color: #8592AD;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.header-dropdown-icon {
  width: 20px;
  height: 20px;
}

.header-search-bar.ant-input-outlined:hover {
  border-color: #d9d9d9;
}

.user-image {
  border: 0;
  background: transparent;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}


.profile-photo-header img {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  cursor: pointer;
}

.dropdown-menusss {
  position: absolute;
  top: 80px;
  right: 10px;
  background-color: white;
  /* border: 1px solid #ccc; */
  border-radius: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-items {
  padding: 10px 20px;
  cursor: pointer;
  text-decoration: none;
  color: #333;
  display: block;
  border-bottom: 1px solid #ccc;
  text-align: left;
}

.dropdown-items:last-child {
  border-bottom: none;
}

.dropdown-items:hover {
  background-color: #eef6fb;
}

/* ================================================================================================== */

.line-dashed {
  border-bottom: 2px solid #e1e7ef;
  margin: 8px 0 16px 0;
}

.sidebar {
  width: 280px;
  position: fixed;
  top: 0;
  left: 0px;
  height: 100%;
  background-color: #ffffff;
  transition: left 0.3s ease;
  z-index: 1000;
  border-right: 1px solid #d4d4e2;
  box-shadow: 10px 0 15px -3px rgba(0, 0, 0, 0.1);
}

.sidebar.open {
  left: 0;
}

/* .toggle-btn {
    position: absolute;
    top: 10px;
    left: 10px;
  } */

.sidebar-content {
  padding: 20px 12px 24px 12px;
}

.sidebar-header {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.sidebar-header img {
  width: 140px;
  padding: 0 0 30px 0;
  margin-left: 30px;
}

.sidebar-nav {
  overflow-y: auto;
  max-height: calc(90vh - 60px);
  text-align: left;
}

.sidebar-nav::-webkit-scrollbar {
  width: 4px;
}

.sidebar-nav::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.sidebar-nav::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar-nav ul {
  list-style-type: none;
  padding: 0;
}

.sidebar-nav ul li {
  margin-bottom: 12px;
}

.sidebar-nav ul li a {
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  color: #a0aec0;
  border-radius: 24px;
  text-decoration: none;
  padding: 10px 20px;
}

.sidebar-nav ul li a:hover {
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  color: #000;
  background: #eef6fb;
}

.sidebar-nav ul li a svg {
  margin-right: 10px;
}

.sidebar-footer {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.sidebar-footer a {
  font-size: 15px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0;
  color: #a0aec0;
  border-radius: 24px;
  text-decoration: none;
}

.sidebar-icon {
  color: #8b909a;
}

.active-nav-links {
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0;
  color: #000 !important;
  background: #eef6fb !important;
  margin-bottom: 15px;
}

.active-nav-links .sidebar-icon {
  color: #8b909a;
}

.sidebar-nav ul li a:hover .sidebar-icon {
  color: #8b909a;
}

.main-wrapper {
  padding: 24px 32px;
  margin-left: 275px;
}

.main-wrapper.collapsed {
  margin-left: 80px;
}


.toggle-btn {
  display: none;
}

.collapse-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 20px;
}

.sidebar-nav.collapsed {
  margin-top: 30px;
}

.navbar-header.collapsed {
  margin-left: 80px;
}

@media (max-width: 768px) {
  .toggle-btn {
    position: absolute;
    top: 29px;
    left: 10px;
    border: none;
    color: #1b3a57;
    background-color: #fff;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .sidebar {
    /* width: 100%; */
    left: -100%;
  }

  .sidebar.open {
    left: 0;
  }

  .main-wrapper {
    padding: 10px;
    margin-left: 0;
  }

  .mobile-search {
    margin-left: 0 !important;
  }

  .navbar-header {
    margin-left: 50px;
  }

  .header-search-bar {
    display: none;
  }
}

.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.sidebar.collapsed {
  width: 80px;
  /* Collapsed to icon size */
}

.navbar-header.collapsed {
  margin-left: 80px;
}
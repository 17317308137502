.ant-collapse-header {
  display: flex;
  justify-content: space-between;
  text-align: left;
  transition: margin-right 0.3s ease;
  margin-right: 0;
  background-color: #1B3A57;
  color: hsl(0, 0%, 100%);
  border-radius: 12px;
  position: absolute;
  align-items: center;
  transition: width 0.3s ease;
}
.ant-collapse-header.swiped {
  margin-right: 100px; /* Adjust this value based on how much you want to reveal */
}
  :where(.css-dev-only-do-not-override-m4timi).ant-collapse .ant-collapse-content {
    border-radius: 12px;
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-collapse>.ant-collapse-item{
    border: none;
  }
  .ant-collapse{
    border: none;
  }
  .ant-collapse-item{
    margin-bottom: 20px;
  }
  .ant-collapse-content{
    border: 1px solid #aaa1a1;
    margin-top: 20px;
    color: #1B3A57;
  }
  .ant-collapse-header-text{
    color: #fff;
  }
  .custom-collapse .ant-collapse-header .ant-collapse-arrow {
    color: #fff; /* Change this color to whatever you prefer */
  }
  .ant-collapse-item >.ant-collapse-header .ant-collapse-expand-icon {
    display: none !important;
  }
  .ant_collapse_content_p1{
    color: #1B3A57;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
  }
  .ant_collapse_content_p2{
    color: #1B3A57;
    text-align: left;
  }
  .dash_icon{
    text-align: end;
    color: #fff;
  }
  .ant-collapse {
    border: none;
  }
  .add_title_question{
    border: 1px solid #cccccc;
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    padding: 10px;
    width: 100%;
  }
  .add_product_reactquill_input{
    border: none;
    width: 100%;
    margin-bottom: 10px;
  }
  .add_product_reactquill_input:focus{
    outline: none;
  }
  .swipe-header {
    display: flex;
    align-items: center;
  }
  .delete-button {
    margin-left: -20px;
  }
  .ant-collapse-item-active .delete-button {
    visibility: visible;
  }
  .swiped .delete-button {
    opacity: 1;
  }
  .plus_icon {
   
    color: #fff;
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-btn-primary.ant-btn-dangerous {
    right: -80px; /* Adjust this value based on your design */
    top: 50%;
    transform: translateY(-50%);
    transition: right 0.3s;
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-btn-primary.swiped .delete-button {
    right: -30px; /* Adjust this value based on your design */
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-btn-primary.ant-btn-dangerous {
    background: #ff4d4f;
    box-shadow: 0 2px 0 rgba(255, 38, 5, 0.06);
    color: #fff;
    position: absolute;
  }
  .add_faqs_edit_icon{
    margin-left: 20px;
    height: 20px;
    width: 20px;
    color: rgb(129, 152, 255);
    background-color: #e4e4e4;
    padding: 5px;
    border-radius: 8px;
  }
  .add_faqs_delete_icon{
    margin-left: 15px;
    height: 20px;
    width: 20px;
    color: rgb(221, 33, 33);
    background-color: #e4e4e4;
    padding: 5px;
    border-radius: 8px;
  }

  .swiped .ant-collapse-header {
    width: 90%;
  }
  /* .container {
    padding: 20px;
  } */
  .ant-collapse-panel {
    position: relative;
  }
  
  .ant-collapse-content-box {
    padding: 10px 20px;
  }
  
  .ant_collapse_content_div {
    padding: 10px;
  }
  
  .ant_collapse_content_p1 {
    font-weight: bold;
  }
  
  .ant_collapse_content_p2 {
    margin-top: 5px;
  }
  
  .add_faqs_edit_text {
    margin-right: 5px;
  }
  
  .add_faqs_edit_icon {
    vertical-align: middle;
  }
  :where(.css-dev-only-do-not-override-m4timi).confirm-delete-header-button .ant-btn-dangerous{
    position: relative;
  }
:where(.css-m4timi).ant-collapse>.ant-collapse-item:last-child, :where(.css-m4timi).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
  border-radius: 12px 12px 12px 12px;
}

.notification-model-div{
    width: 70% !important;
}
.notification-model-header{
    display: flex;
    align-items: center !important;
    font-size: 10px;
    justify-content: space-between;
    background-color: #EEF6FB;
    padding: 15px;
    border-radius: 10px;
}
.notification-model-buttons-div{
    display: flex;
    align-items: center;
    gap: 10px;
}
.notification-model-span span{
    font-weight: 600;
    font-size: 14px;
}
.notification-model-span p{
    /* font-weight: 600; */
    font-size: 13px;
    margin-bottom: 0;
}
.notification-model-button-left{
    border: 1px solid  #cccccc;
    background-color: #EEF6FB;
    border-radius: 10px;
    font-weight: 600;
    font-size: 10px;
    padding: 5px;
}
.notification-model-button-right{
    border: none;
    background-color: #EEF6FB;
    border-radius: 10px;
    font-weight: 600;
    font-size: 10px;
}
.notification-model-settings-button{
    border: none;
    font-size: 18px;
    background-color: #fff;
    font-weight: 600;
    color: #1b3a57;
}
.notification-model-h4{
    font-weight: 800;
    margin-bottom: 0px;
}
.notification-model-menu-divs{
    border-bottom: 2px solid #dddddd;
    display: flex;
    gap: 10px;
    padding-bottom: 20px;
    padding-top: 20px;
}
.notification-page-dot{
    background-color: #1b3a5f;
    height: 8px;
    width: 8px;
    border-radius: 4px;
    margin-top: 8px;
}
.notification-model-menu-span{
    font-weight: 600;
}
.notifiation-model-icon-div{
    display: flex;
    gap: 10px;
}
.notification-model-human-icon{
    background-color: #f3dede;
    border-radius: 15px;
}
.notification-scrollbar-container{
    height: 300px; /* Set a fixed height for the scrollable area */
    overflow-y: auto;
}
.notification-scrollbar-container::-webkit-scrollbar {
    width: 4px;
  }
  .notification-scrollbar-container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .notification-scrollbar-container::-webkit-scrollbar-track {
    background-color: transparent;
  }
@media (max-width: 768px){
    .notification-model-header{
        display: flex;
        flex-direction: column;
    }
    .notification-model-span{
        margin-bottom: 15px;
    }
    .notification-model-span span{
        font-size: 12px;
    }
    .notification-model-span p{
        font-size: 12px;
    }
    .notification-model-h4{
        margin-bottom: 0px;
    }
}
.mark-as-read-button{
    border: none;
    font-size: 14px;
    background-color:  #EEF6FB;
    border-radius: 10px;
    padding: 10px;
    font-weight: 600;
    color: #1b3a57;
}
.mark-as-read-button:hover{
   
    background-color:  #1b3a57;
    color: #fff;
}
.brand-search:where(.css-dev-only-do-not-override-m4timi).ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: #fff;
    background-color: #1b3a57;
}
.search-brand-card{
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 80px;  
}
.select-category-brands.css-b62m3t-container{
  width:180px !important;
}

.product-brand-card {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    height: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .product-image img {
    width: 200px;
    height: 200px;
  }
  
  .product-brand-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .edit-brand-button {
    border-radius: 20px;
    padding: 4px 15px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
  }
  
  .edit-brand-button:hover {
    background-color: #f0f0f0;
  }
  
  .product-brand-name {
    font-size: 1.1em;
    font-weight: bold;
  }
  
  .product-brand-weight {
    display: block;
    margin-top: 5px;
  }
  
  .product-brand-info p {
    margin: 0;
    font-size: 14px;
    color: #d3d3d3;
  }
  
  .product-brand-info span {
    display: block;
    margin-top: 5px;
    color: #1b3a57;
  }



.add_brand_label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.custom-placeholder {
    width: 100%;
}

.add_brand_radio {
    display: flex;
    gap: 10px;
    margin-top: 5px;
}

@media (max-width: 768px) {
  .brands-media{
    flex-direction: column;
  }
  .search-media{
    flex-direction: column;
  }
  .product-brand-card {
    margin: 0 auto;
    max-width: 90%;
  }

  .slick-slider {
    overflow: hidden;
  }

  .slick-list {
    padding: 0;
  }

  .slick-dots {
    bottom: -30px;
  }

  .custom-arrow {
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .custom-next {
    right: -6px;
  }

  .custom-prev {
    left: -7px;
  }
  .product-brand-name{
    font-size: 17px;
    text-align: left;
    margin-bottom: 0px !important;
  }
  .product-brand-details{
    flex-direction: column;
    gap: 10px;
  }
  .brand-product-name-weight{
    display: flex;
    gap: 20px;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .add-brands-button{
    float: left;
  }
}


/* ==========css by keerthi============== */
.add_image_divs{
  /* border: 1px solid #bebebe;
  height: 200px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 10px;
  max-width: 100%; 
  margin: 0 auto; 
  border: 1px solid #bebebe;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.previewImage-brand{
  max-width: 100%; 
  max-height: 160px; 
  width: 300px;
  object-fit: cover;  
}

.error-card {
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100vh; 
  text-align: center;
  margin-top: 40px;
}

.error-card-content {
  background-color: #f8d7da; 
  color: #721c24; 
  border: 1px solid #f5c6cb; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
}
.add-brands-button{
  background-color: #1b3a57;
  color: #fff;
  padding: 10px;
  border-radius: 12px;
  border: none;
}

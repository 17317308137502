.review-card-div{
    border: 1px solid #e7e7e7;
    padding: 12px;
}
.review-card-div p{
    color:#84818a;
    font-size: 16px;
    text-align: left;
    border-radius: 12px !important;
}
.review-reply-button{
    background-color: #fff;
    color:#ce1b28;
    text-decoration: underline;
    border: none;
}
@media (max-width: 768px){
    .reviews-how-to-apply-div{
        display: flex;
        flex-direction: column;
    }
    .reviews-how-to-apply{
        text-align: left;
    }
    .reviews-posted-at{
        text-align: right !important;
    }
    
}
.no-inquiries{
   margin-top: 100px;
}
.no-inquiries p{
    font-size: 22px;
    margin-top: 5px;
    color: #1b3a57;
}
.customizable_heading{
    color: darkslategrey;
    text-align: left;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.admin_role_list_p{
    display: flex;
    padding-left: 25px;
    align-items: center;
    justify-content: space-between;
    color: rgb(129, 129, 129);
}
:where(.css-dev-only-do-not-override-m4timi).ant-switch.ant-switch-checked {
    background: #1BE53B;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #15c532;
}
.admin_role_title{
    text-align: left;
    margin-bottom: 10px;
}

@media (max-width: 768px){
    .admin_role_list_p{
        padding-left: 0px;
        gap: 20px;
        align-items: center;
    }
    .admin_role_para{
        text-align: left;
    }
    .main-wrapper{
        width: auto;
    }
}

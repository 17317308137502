

  
  .setting-sidebar {
    width: 100%;
    border-right: 1px solid #ddd;
    padding-right: 20px;
    height: auto !important;
  }
  
  .setting-sidebar .settings-items {
    display: flex;
    padding: 15px 20px;
    cursor: pointer;
    text-decoration: none;
    color: #757575;
    display: block;
    text-align: left;
  }
  
  .setting-sidebar .settings-items.active  {
    background-color: #1b3a57;
    border-radius: 15px;
    color: #fff;
    width: auto;
    margin-left: 10px;
    margin-top: 3px;
    /* padding: 5px; */
    
    /* margin: auto; */
  }
  
  .content-profile {
    flex-grow: 1;
    padding: 0px 20px;
  }
  
  .upload-button {
    border: 0;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .upload-button:hover {
    opacity: 0.8;
  }
  .ant-input-affix-wrapper {
    width: 100%;
  }
 
  .profile-icon-section {
    text-align: center;
    display: flex;
    align-items: center;
  }
  
  .profile-image {
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
  
  .edit-icon-button {
    border: none;
    /* color: #fff; */
    cursor: pointer;
    /* background: #ce1b28; */
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: relative;
    left: -20px;
    top: 40px;
  } 
  
  /* .edit-icon-button:hover {
    color: #ce1b28;
    background: #fafafa;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  } */

  /* Settings.css */

.ant-input-affix-wrapper .ant-input {
    color: #000;
    letter-spacing: 0.1em;
  }
  
  .ant-input-password-icon {
    font-size: 20px;
    color: #000;
  }
  .form-check-input:checked {
    background-color: #1b3a57 !important;
    border-color: #1b3a57 !important;
}
:where(.css-dev-only-do-not-override-m4timi).ant-picker-outlined{
  width: 100%;
}
.setting-sidebar {
  height: 200px;
  /* width: 250px; */
  padding: 10px 15px;
}

@media (min-width: 1400px) {
  .setting-sidebar {
    height: 200px;
    /* width: 250px; */
    padding: 10px 15px;
  }
}

@media (max-width: 768px){
  .settings-personal-info-div{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 20px;
  }
  .settings-personalinfo-row{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .settings-personalinfo-buttons{
    display: flex !important;
    align-items: center;
    justify-content: center !important;
    gap: 30px;
  }
  .storeinfo-email{
    margin-bottom: 20px;
  }
  .storeinfo-reviews-comments{
    text-align: left;
  }
  .storeinfo-form-check-label{
    float: left;
    margin-left: 20px;
  }
  .storeinfo-form-check-label-rating{
    text-align: left;
  }
  .content-profile {
    flex-grow: 1;
    padding: 0px 0px;
    margin-top: 20px;
  }

}

.error-message {
  color: #ff4d4f; 
  font-size: 12px; 
  margin-top: 4px; 
}
.required-star {
  color: red;
  margin-left: 5px;
}
input::placeholder {
  color: #d3d3d3 !important;
}
textarea::placeholder {
  color: #d3d3d3 !important;
}

.time-sector-width{
  width: 100% !important;
}

.form-control{
  font-size: 14px !important;
}



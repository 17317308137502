.custom-select-status .ant-select-selector {
    background-color: #0f68ed !important;
    color: white !important;
    width:130px !important
  }
  
  .custom-select-status .ant-select-dropdown {
    background-color: #0f68ed  !important;
    color: white !important;
  }
  .custom-select-status.ant-select-single .ant-select-selector .ant-select-selection-placeholder{
    color:#fff;
  }
  
  .custom-select-status .ant-select-item-option-content {
    background-color: #0f68ed  !important;
    color: white !important;
  }
  .custom-select-status.ant-select .ant-select-arrow{
    color:#fff !important;
  }
  .custom-select-status .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: darkblue !important;
    color: white !important;
  }
  .manage-order-menu.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected{
    color:#1b3a57 !important;
    font-size: 18px !important;
    font-weight: 500 !important; 
  }
  .manage-order-menu.ant-menu-light .ant-menu-item{
    color:#8b909a !important;
    font-size: 18px !important;
    font-weight: 500 !important; 
}
.manage-order-menu.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after{
    border-bottom-color: #1b3a57 !important;
}
.manage-order-menu.ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after{
    border-bottom-color: #1b3a57 !important;

}
.ant-table-wrapper .ant-table-cell .ant-table-container > .ant-table-content {
  background-color: #fafafa !important;
}
.custom-child-table .ant-table-thead > tr > th {
  background-color: #fafafa !important;
}
.summary-div p{
  color:#8b909a;
}

.manage-orders-subtotal-div{
  float: right;
}

.ant-table-wrapper .ant-table-row-expand-icon-cell{
  display: none;
   /* width:10px;  */
}
.address-label{
  width: auto !important;
}
.address-value{
  width: 380px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .shippedlist-subtotal-div{
    display: flex !important;
    justify-content: end !important;
  }
  
  .media-manageorder{
    width: 350px;
  }
  .search-table-container :where(.css-dev-only-do-not-override-m4timi).ant-select-single {
    width: 350px;
  }
  
  .manageorder-media{
    justify-content: end !important;
   margin-left: -123px;
  }
  
  
}


/* .table-data .ant-table-row-expand-icon-cell{
  display: block;
  width:10px;
} */
.table-data:where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-cell, :where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-thead>tr>th, :where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-tbody>tr>th, :where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-tbody>tr>td, :where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper tfoot>tr>th, :where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper tfoot>tr>td{
  padding: 16px 4px !important;
}

:where(.css-dev-only-do-not-override-m4timi).ant-table-wrapper .ant-table-row-expand-icon-cell .ant-table-row-expand-icon{
  display: none;
}
.nested-table-details .ant-table-thead > tr > th {
  background-color: #fff !important;
}
.nested-table-details .ant-table-cell .ant-table-container > .ant-table-content {
  background-color: #fff !important;
}
.nested-table-details.ant-table-wrapper .ant-table-tbody >tr >td >.ant-table-wrapper:only-child .ant-table{
margin-inline: 0;
}




.ant-carousel .slick-next::after  {
  font-size: 35px !important;  /* Thicker arrow */
  /* color: #fff !important;     */
  /* background-color: #1b3a57 !important; */
  margin-left: 4px;
  margin-top: 5px;
  /* padding: 12px; */
  /* border-radius: 50%; */
}

.ant-carousel .slick-prev::after  {
  font-size: 35px !important;  /* Thicker arrow */
  /* color: #fff !important;       */
  /* background-color: #1b3a57 !important; */
  margin-left: 8px;
  margin-top: 5px;

}
.ant-carousel .slick-prev{
  opacity: 1 !important;
}
.ant-carousel .slick-next{
  opacity: 1 !important;
}


:where(.css-dev-only-do-not-override-m4timi).ant-carousel .slick-next {
  background: #d3d3d3 !important;
  color: #1b3a57 !important;
  border-radius: 50%;
  padding: 13px;
  /* margin-right: -30px !important; */
}
:where(.css-dev-only-do-not-override-m4timi).ant-carousel .slick-prev {
  background: #d3d3d3 !important;
  color: #1b3a57 !important;
  border-radius: 50%;
  padding: 13px;
  /* margin-left: -30px !important; */
}
.slick-next:hover{
  background: #1b3a57 !important;
  color: #fff !important;

}
.slick-prev:hover{
  background: #1b3a57 !important;
  color: #fff !important;
  
}



@media (max-width: 768px){

  :where(.css-dev-only-do-not-override-m4timi).ant-carousel .slick-next {
    /* margin-right: -12px !important; */
    z-index: 1000 !important;
    position: absolute !important;
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-carousel .slick-prev {
    /* margin-left: -12px !important; */
    z-index: 1000 !important;
    position: absolute !important;
  }
  .ant-modal .ant-modal-content{
    padding: 20px;
    z-index: 10 !important;
    position: relative !important;
  }
  .pagination-info{
    display: flex !important;
    flex-direction: column !important;
    margin-top: 20px;
  }
  
}















.productlist-information-details h5{
    text-align: left;
    color:#5d6679;
    font-size: 16px;
}
.productlist-information-details p{
    text-align: left;
    color:#8b909a;
    font-size: 14px;
}
.productlist-information-details h4{
    text-align: left;
    margin-top: 3px;
    font-size: 16px;
}
.productlist-information-details{
    /* border-right: 1px solid #f0f1f3; */
    
    
}
/* .productlist-information-details:last-child{
    border-right: none;
}
.productlist-information-details{
    width: fit-content !important;
    height: 30px;
} */
/* .products-detail-information{
    border-bottom: 1px solid #8b909a;
    margin-bottom: 50px;
    gap: 2px;
    
} */
/* .products-detail-information p{
    margin-bottom: 4px;
} */
.add-product-button{
    border: 1px solid #1b3a57;
    padding: 8px 16px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    background: #1b3a57;
}
.filters-button{
    border: 1px solid #d0d3d9;
    padding: 8px 16px;
    border-radius: 6px;
    color: #5d6679;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    background: #fff;
}
.view-product-details{
    text-align: left;
    margin-top: 20px;
    display: flex;
    gap: 100px;
}
.view-product-details p{
    color: #858d9d;
    font-size: 16px;
}
.product-details-image{
    border: 1px dashed #8b909a;
    width: 150px;
    height: 150px;
}
.save_changes_button:hover {
    background-color: #dfdfdf !important;
    border-color: #b1b1b1 !important;
    color: #1b3a57 !important; /* Optionally, change text color on hover */
  }
  .discard_button{
    background-color: #dfdfdf !important;
    border-color: #b1b1b1 !important;
    color: #1b3a57 !important;
  }
  .discard_button:hover{
    background-color:#1b3a57 !important;
    color: #fff !important;
  }
@media (max-width: 768px) {
    .product-column{
        flex-direction: column;
    }
    /* .productlist-information-details   {
        border-bottom: 1px solid #d3d3d3; 
        border-bottom-width: 40% !important;
    } */
    .media-productlist{
        width: 350px;
    }
    .productlist-button{
        flex-direction: column;
    }
    .productList-mobile{
        display: flex;
        flex-direction: column;
    }

} 

.button-image:where(.css-dev-only-do-not-override-m4timi).ant-btn >span {
    display: inline-block;
    color: black;
}
.account-card{
    /* padding: 10px ; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
}
.products-detail-information {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    width: 100%;
  }
  .productlist-information-details:first-child {
    flex-grow: 1;
    flex-basis: 16%; /* Smaller width for the first card */
}

.productlist-information-details:nth-child(2) {
    flex-grow: 1;
    flex-basis: 22%; /* Medium width for the second card */
}

.productlist-information-details:nth-child(3) {
    flex-grow: 1;
    flex-basis: 28%; /* Medium width for the third card */
}

.productlist-information-details:last-child {
    flex-grow: 1;
    flex-basis: 34%; 
} 
.productlist-information-details {
    flex: 1;
}
  
  /* .icon-1-img {
    max-width: 100%;
  } */
 @media (max-width : 768px){
    .products-detail-information{
        display: flex;
        flex-direction: column;
    }
 }

 @media (max-width: 1024px) {
    .products-detail-information {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .productlist-information-details {
        flex: 1 1 50%; 
        margin-bottom: 1rem; 
    }
}
.product-list-slider {
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    gap: 0;
  }
  
  .product-list-slider h5 {
    display: inline-block;
    animation: slide 10s linear infinite;
  }

  .product-list-slider-text{
    white-space: nowrap; 
    text-overflow: ellipsis; 
    display: inline-block;
  }
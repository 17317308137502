.edit-product-category {
  border: 2px solid #1b3a57;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  padding: 8px;
  color: #1b3a57;
  justify-content: center;
  margin-top: 12px;
  font-size: 15px;
}
.edit-product-category-viewAll {
  border: 2px solid #1b3a57;
  background-color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  color: #1b3a57;
  justify-content: center;
  font-size: inherit;
  padding:8px;
  
}
.add_new_coupon_button-viewAll{
  background-color: #1B3A57;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  color: #fff;
  justify-content: center;
  font-size: inherit;
  padding:8px;
}
.ViewAll-products-previous{
  background-color: #1B3A57;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  color: #fff;
  justify-content: center;
  font-size: 16px;
  padding:8px 18px;
}
.viewAll-slider{
  width: 250px;
  margin-left: 12px;
}
.product-category-card .slider-container {
  width: 200px;
  margin: 0 auto;
}
.product-category-img {
  width: 250px;
  height: 200px;
}
.product-category-img-viewAll {
  width: auto !important;
  height: 180px;
}
.product-category-card {
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  padding: 12px;
  /* width: 320px; */
  border-radius: 8px;
  /* Optional: to give rounded corners */
}
.product-category-card p {
  margin-bottom: 0;
}
.product-category-card h6 {
  font-weight: 700;
}
.slick-prev:before {
  color: #92969e !important;
}
.slick-next:before {
  color: #92969e !important;
}
.edit-product-model.ant-modal .ant-modal-content {
  width: 910px;
  margin-left: -150px;
}
.product_category_add_image_div {
  border: 1px solid #bebebe;
  height: 250px;
  width: 280px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.product_category_image_1 {
  margin-left: 10%;
  height: 200px;
  width: auto;
  margin-top: 0;
}
.product_categories_additional_images_divs {
  border: 1px solid #d6d5d5;
  height: 110px;
  width: 110px;
  margin-bottom: 25px;
  border-radius: 12px;
}
.product_categories_image_2 {
  height: 90px;
}
.product_categories_upload_image_icon {
  margin-top: 20px;
  width: 40px;
  height: 40px;
  color: #bbc0c5;
  background-color: #1B3A57;
  padding-top: 0px;
  border-radius: 8px;
  margin-left: 25%;
}
.product_categories_additional_images_divs_d2 {
  background-color: #f3f3f3;
  justify-content: center;
}
.product_categories_additional_images_divs_d3 {
  border: 2px dashed #d6d5d5;
}
.product_categories_additional_images_divs_d4 {
  border: 2px dashed #d6d5d5;
}

/* .productCategories-buttons-div{
  display: flex;
  gap: 3px;
  align-items: center !important;
  justify-content: space-between;
} */
.productCategories-buttons-div {
  display: flex;
  justify-content: space-between; 
  align-items: center;
  gap: 10px; 
  flex-wrap: nowrap; 
  width: 100%; 
  box-sizing: border-box; 
}

.edit-product-category,
.add_new_coupon_button {
  padding-right: 25px !important;
  flex: 1; 
  text-align: center; 
  white-space: nowrap; 
  padding: 8px 20px; 
  max-width: 48%; 
  box-sizing: border-box;
  font-size: 15px;
}
.ViewAll-button{
  border: none;
  background: transparent;
  color: #1b3a57;
}
@media (max-width: 768px) {
  .main-wrapper {
    padding: 10px;
    margin-left: 15px;
    width: 400px;
  }
  .product-category-card {
    position: relative;
    border-radius: 10px;
    /* width: 330px; */
  }
  .main-title-all span {
    font-size: 17px;
    margin-bottom: 13px;
  }
  .product-add-categories-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .slick-prev, .slick-next {
    z-index: 88;
    position: absolute; 
    top: 50%; 
    width: 30px;
    height: 100%; 
  }
  .slick-prev {
    left: -10px; 
    color: #000000;
  }
  .slick-next {
    right: -5px;
  }
  .edit-product-model.ant-modal .ant-modal-content{
   margin-left: 0px;
   width: auto;
  }
  .edit-product-div{
    display: flex;
    flex-direction: column;
  }
  .add_image_div{
    width: auto;
  }
  .edit-product-brand{
    display: flex;
    flex-direction: column;
  }
  .all-categories-grid-viewAll{
    grid-template-columns: 1fr !important;
  
  }
  .all-categories-card-viewAll{
    width: auto !important;
  }
  .add_new_coupon_button-viewAll{
    padding: 5px;
    font-size: 14px;
  }

  .edit-product-category-viewAll{
    padding: 5px;
    font-size: 14px;
  }
}

@media (max-width: 920px) {
  .row {
    flex-direction: column;
    align-items: center; 
    gap: 16px;
  }

  .product-category-card {
    width: 100% !important; 
  }
}
.all-categories-card {
  flex: 0 0 calc(100% / 3 - 16px); 
  margin-right: 16px; 
}

.all-categories-card:last-child {
  margin-right: 0; 
}
.edit-product-image-wrapper {
  position: relative;
  display: inline-block;
}

.edit-product-image {
  display: block;
  width: 100px;
  height: 100px;
}
.edit-product-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.edit-product-hover-icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  gap: 10px;
}

.edit-product-preview-icon,
.edit-product-delete-icon {
  font-size: 18px;
  cursor: pointer;
  color: white;
}

.edit-product-image-wrapper:hover .edit-product-overlay {
  opacity: 1; 
}

.edit-product-image-wrapper:hover .edit-product-hover-icons {
  display: flex; 
}
.productCategories-discount {
  color: #fff;
  background-color: #1B3A57;
  border-radius: 20px;
  font-size: 12px;
  padding: 5px 10px;
}

.all-categories-grid-viewAll {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 20px;
}

.all-categories-card-viewAll {
  background-color: #fff;
  border-radius: 10px;
  width: 320px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
}
.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
   opacity: 0.8; 
}

@media (min-width: 1200px) and (max-width: 1350px) {
  .col-custom {
    flex: 0 0 50% !important; 
    max-width: 50% !important;
  }
}
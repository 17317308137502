.coupon_code_header{
    font-weight: 600;
    font-size: 22px;
    color: #23272E;
    text-align: start;
}
.add-new-coupon{
    margin-top: 20px;
}

.add_new_coupon_button{
    background-color: #1B3A57;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    padding: 8px;
    color: #fff;
    justify-content: center;
    margin-bottom: 25px;
}
.search_contents{
    display: flex;
    justify-content: space-between;
}
.table_first_row{
    font-weight: 500;
}

.delete_edit_icons{
    display: flex;
    align-items: center;
    gap: 15px;
}
.delete_icon{
    color: #FB5757;
    height: 20px;
    width: 20px;
    cursor: pointer;
}
.edit_icon{
    color: #E7B683;
    height: 20px;
    width: 20px;
    cursor: pointer;
    
}
.add_couponpage_hedings{
    font-weight: 600;
    font-size: 14px;
}
.custom-placeholder::placeholder {
    font-size: 12px; 
  }

  
  .add_coupon_radio .ant-radio-checked .ant-radio-inner {
    border-color: #1B3A57;
    background-color: #fff; 
  }
  
  .add_coupon_radio .ant-radio-checked .ant-radio-inner::after {
    background-color: #1B3A57;
    border-color: #1B3A57; 
  }
  
  .add_coupon_radio .ant-radio-inner {
    border-color: #1B3A57;; 
  }
  .add_coupon_radio .ant-radio:hover .ant-radio-inner {
    border-color: #1B3A57;
  }
  
.custom-date-picker{
    width: 100%;
}

.custom-date-picker.ant-picker .ant-picker-input >input:placeholder-shown{
    font-weight: 700 !important; 
    font-size: 12px !important;
}

.save_changes_button{
    background-color: #1B3A57;
    font-size: 12px;
    border-radius: 12px;
}

.discard_button{
    background-color: #f5f5f5;
    border: none;
    font-size: 12px;
    border-radius: 12px;
}

.add_coupon_checkbox{
    color: #1B3A57;
    background-color: #fff;
}
.add_coupon_checkbox .ant-checkbox-inner {
    background-color: #fff;
    border-color: #1B3A57;
}
.add_coupon_checkbox:hover .ant-checkbox-inner {
    background-color: #fff;
    border-color: #1B3A57; 
}
.add_coupon_checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff;
    border-color: #1B3A57;
}
.add_coupon_checkbox:hover .ant-checkbox-inner {
    background-color: #fff;
    border-color: #1B3A57; 
}
.add_coupon_checkbox .ant-checkbox-inner::after {
    border-color: #1B3A57;
    background-color: #fff;
}
.ant-radio-wrapper .ant-radio:hover{
color: #1B3A57;
}
.coupon-text{
    margin-top: 10px;
}


@media (max-width: 768px){
    .select-options input{
        height: 42px;
    }

     .ant-input-outline{
       width:250px;
    }
    .coupon-text{
        margin-top: 10px;
    }
    .media-coupon{
     width: 350px;
    }
    
}

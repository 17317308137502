.filter-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .select-options{
    display: flex;
    align-items: center;
  }
  .ant-table-wrapper .ant-table-thead >tr>th{
    color:#8b909a !important;
    font-weight: 400 !important;
    background: #fff;
  }

  .main-title-all {
   
    margin-bottom: 24px;
  }
  
  .main-title-all span {
    font-size: 22px;
    font-weight: 700;
    color: #1e1e1e;
    letter-spacing: 0.3px;
    text-align: left;
  }
  
  .main-title-all button {
    border: 1px solid #1b3a57;
    padding: 8px 16px;
    border-radius: 6px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    background: #1b3a57;
    justify-content: end;
  }
  
  .coupon_code_heading{
    font-size: 13px;
}
.status-option.css-b62m3t-container{
  width: 200px !important;
}
.status-option.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
width: 250px;
}
.viewdetails-model-span{
  font-weight: 600;
}
.view-details-model-div{
  padding-left: 5%;
}
.view-details-model-div span{
  width: 150px;
}
@media (max-width: 768px) {
.table-list{
  overflow-x: auto;
}
.search-table-container{
  width: 100%;
}
.filter-row{
  display: flex;
  flex-direction: column;
  gap:20px
}
.media-select-transaction{
  flex-direction: column;
  gap: 15px;
  width: 350px;
}
.status-option.css-b62m3t-container{
  width: 350px !important;
}
.status-option.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  /* width: auto !important; */
}
}


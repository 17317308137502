.add_product_heading{
    text-align: left;
}
.add_voucher{
    display: flex;
    justify-content: space-between;
    
}
.custom-date-picker {
    font-size: 20px;
    color: black;
    font-weight: 500;
    padding: 10px;
    width: 100%;
}
.custom-date-picker input::placeholder{
    font-size: 15px;
}
.product_description_heading{
    text-align: left;
    margin-bottom: 0;
}
.editor-container {
    width: 100%;
    margin:  0;
  }
  
  .ql-container {
    min-height: 130px;

  }
  
  .ql-editor {
    min-height: 150px;

  }
  .ql-textDropdown1 {
    min-width: 120px; /* Adjust width as needed */
}

.ql-textDropdown2 {
    min-width: 120px; /* Adjust width as needed */
}
.long_description_p{
    text-align: left;
    margin-bottom: 0;
}
.visibility{
    text-align: left;
    margin-bottom: 0;
}
.visibility_header{
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 14px;
}
.date_added__heding{
    text-align: left;
}
.gray_text_p{
    color: gray;
}
.switch-input.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background: #1b3a57;
}
.switch-input.ant-switch.ant-switch-checked {
    background: #1b3a57;
}


.add_image_div{
    border: 1px solid #bebebe;
    width: 70%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.add_image_icons{
    display: flex;
    justify-content: right;
    gap: 10px;
    padding-right: 20px;
    padding-top: 20px;
    cursor: pointer;
}
.add_image_upload_icon{
    width: auto;
    height: auto;
    background-color: #bebebe;
    padding: 5px;
    border-radius: 5px;
}
.add_image_delete_icon{
    width: auto;
    height: auto;
    background-color: #bebebe;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}
.image_1{
    width: 70%;
    height: auto;
    margin-top: 0;
}
.additional_images_div{
    display: grid;
    /* gap: 10px; */
    grid-template-columns: 1fr 1fr 1fr;
}
.additional_images_divs{
    border: 1px solid #d6d5d5;
    height: 140px;
    width: 150px;
    margin-bottom: 25px;
    border-radius: 12px;
}
.additional_images_divs_d1{
    display: flex;
    align-items: flex-start;
    justify-content: end;
    padding-top: 10px;
    padding-right: 10px;
}
.additional_images_divs_d2 {
    background-color: #f3f3f3;
    justify-content: center;
}
.upload_image_icon{
    margin-top: 40px;
    width: 40px;
    height: 40px;
    color: #bbc0c5;
    background-color: #1B3A57;
    padding-top: 0px;
    border-radius: 8px;
}
.upload_image_div{
    display: flex;
    gap: 5px;
    cursor: pointer;
    
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.upload_image_p{
    font-size: 12px;
    margin-bottom: 0;
}
.additional_images_divs_d3{
    border: 2px dashed #d6d5d5;
}
.additional_images_divs_d4{
    border: 2px dashed #d6d5d5;
}
.image_2{
    height: 130px;    
}
.last_row_buttons_div{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}
.add_product_discard_button{
    background-color: #f5f5f5;
    color: #1b3a57;
    border: none;
    font-size: 12px;
    border-radius: 8px;
    padding: 5px;
}
.add_product_save_publish_button{
    background-color: #1B3A57;
    color: #fff;
    border: none;
    font-size: 12px;
    border-radius: 8px;
    padding: 8px;
}
.product_input_field{
    width: 100% !important;
}

@media (max-width: 768px) {
    .additional_images_div{
        grid-template-columns: 2fr 2fr;
    }
    .voucher-coupon-type{
     flex-direction: column !important;
    }
}
.error-message{
    color: red;
    font-size: 0.875em;
    margin-top: 0.25em;

}
.error-add-product{
    display: flex;
    justify-content: start;
}


.card-new-steel {
    border: 1px solid #ddd;
    border-radius: 15px;
    padding: 20px;
    width: 450px;
    margin: auto;
  }
  .card-new-steels{
    width: 380px !important;
  }
  .header-new-steel,
  .row-new-steel {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 10px;
    text-align: start;
  }
  
  .header-new-steel p,
  .row-new-steel p {
    margin: 0;
    flex: 0;
    /* justify-content: space-evenly; */
  }
  
  /* .row-new-steel input {
    width: 50px;
    border-radius: 10px;
    padding: 0px 5px 0px 5px;
  } */

  /* .edit-reactquill .ql-editor{
     height: 100px !important;
     min-height: 100px;
  } */
  .react-quill-wrapper {
    border: 1px solid #ccc; /* Border around the editor */
    height: 190px; /* Total height for the editor container */
    display: flex;
    flex-direction: column; /* Stack toolbar and content vertically */
  }
  
  .react-quill-toolbar {
    flex-shrink: 0; /* Prevent toolbar from shrinking */
  }
  
  .react-quill-container {
    flex: 1; /* Take up remaining space */
    overflow: hidden; /* Hide overflow of the container */
    border-top: 1px solid #ccc; /* Border separating toolbar and content */
  }
  
  .react-quill-container .ql-container {
    height: 100%; /* Full height of container */
    overflow-y: auto; /* Scrollable content area */
  }
  
  .react-quill-container .ql-editor {
    max-height: calc(4 * 1.5em); /* Approximate height for 4 rows (adjust line-height if necessary) */
    overflow-y: auto; /* Make content scrollable if it exceeds max-height */
  }
  
  
  
  
  
  
.days-last{
    color: #8B909A;
    text-align: left;
}
.total-sub-num{
    font-weight: bold;
}
.total-orders-card{
    background-color: #fff;
    width: 330px;
    border: 2px solid #f0f0f0;
    border-radius: 10px;
    padding: 10px 7px !important;
    display: flex;
    flex-direction: column;
    gap:4px;
}
.total-cards-div{
    display: flex;
    flex-direction: row !important;
    gap: 8px;
}
/* DealsDetails css */
.custom-sales-table .ant-table-thead > tr > th.custom-header-cell {
    background-color: #f1faf9;
    padding: 18px; 
    border-bottom: none !important; 
    border-right: none !important; 
    border-left: none !important; 
    font-size: 16px;
}
.custom-sales-table .ant-table-cell {
    border-right: none !important; 
    border-left: none !important; 
}
.custom-sales-table .ant-table-tbody > tr > td:first-child {
    border-left: none !important;
}
.custom-sales-table .ant-table-tbody > tr > td:last-child {
    border-right: none !important;
}
.custom-sales-table .ant-table-thead > tr > th:first-child {
    border-left: none !important;
}
.custom-sales-table .ant-table-thead > tr > th:last-child {
    border-right: none !important;
}
.custom-sales-table .ant-table-tfoot > tr > td:first-child {
    border-left: none !important;
}
.custom-sales-table .ant-table-tfoot > tr > td:last-child {
    border-right: none !important;
}
.custom-sales-table table {
    border-left: none !important;
    border-right: none !important;
}
.custom-sales-table .ant-table-container {
    border-left: none !important;
    border-right: none !important;
}
.total-orders-div{
    text-align: left;
}
.total-orders-div h4{
    font-size: 16px;
}
.total-orders-div p{
    color:#8b909a;
}
.total-orders-div h2{
    font-size: 22px;
}
.sales-details-card{
    width: 100%;
    
}

.total-cards-div{
    --bs-gutter-x: 0px!important;
}

.sales-details-monthly-data{
    width: auto;
    border: 1px solid #dbdbdb;
    color: #8B909A;
    border-radius: 8px;
    font-size: 15px;
    padding: 4px;
}
@media (min-width: 1400px) {
    .total-orders-card{
        display: flex;
        gap:24px;
        padding: 22px;
    }
    .total-cards-div{
        display: flex;
        gap:16px
    }
}

@media (max-width: 992px){
    .total-cards-div{
        display: flex;
        gap: 8px;
        flex-direction: column !important;
    }
    .total-orders-card{
        width: auto !important;
        display: flex;
        justify-content: space-between;
    }
    .ant-table-wrapper {
        overflow-x: auto;
    }
    .sales-details-graph-date{
        display: flex !important;
        flex-direction: column !important;
        /* z-index: 1000; */
    }
}

@media (min-width:769px)  and  (max-width: 1024px) {
    .sales-details-card {
        width: 100% !important;
        
    }
}



.custom-sales-table-dashboard .ant-table-tbody > tr > td {
    line-height: 1.3 !important; /* Adjust line height as needed */
    
}
.custom-sales-table-dashboard .ant-table-thead > tr > th{
    line-height: 1 !important; 
}

.custom-sales-table-dashboard .ant-table-row {
    height: 36px; /* Adjust row height as needed */
}



/* ___________________________________________ */
.custom-sales-table {
    max-height: 400px; 
    overflow-y: auto;  
    border-collapse: collapse;
    
}
.custom-sales-table th {
    position: -webkit-sticky; /* For Safari */
    position: sticky; /* For other modern browsers */
    top: 0; /* Set the top position to 0 */
    background: #fff; /* Ensure background is white to cover content while scrolling */
    z-index: 1; /* Ensure header stays above other rows */
}
.custom-sales-table::-webkit-scrollbar {
    width: 2px; /* Adjust the width of the vertical scrollbar */
}

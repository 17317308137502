.time-sector-width{
    width: 100% !important;
}
.ant-table-cell {
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;
  } 
  .ant-table-column {
    max-width: 150px; 
    min-width: 100px; 
  }
  .StoreInformation-table-row:hover{
    cursor: pointer;
  }
.profile-email-icon {
    border-radius: 50%;
    /* border:2px solid #1b3a57; */
    width: 35px;
    height: 35px;
    align-items: center;
    display: flex;
    /* padding: 5px; */
}

.profile-email-icon img {
    width: 35px;
    height: 35px;
}

.personal-information-details h5 {
    text-align: left;
    color: #8b909a;
}

.personal-information-details h4 {
    text-align: left;
    margin-top: 3px;
}

.personal-information-details {
    border-right: 1px solid #8b909a;

}

.personal-information-details:last-child {
    border-right: none;
}

.customer-detail-information {
    border-bottom: 1px solid #8b909a;
    padding-bottom: 20px;
}

.customer-detail-information p {
    margin-bottom: 4px;
}

.table-list {
    display: flex;
    flex-direction: column;
}

/* .pagination-info {
    margin-top: -45px;
    display: flex;
    align-items: center;
  } */
/* .pagination-info span {
   border: 1px solid #8b909a;
   padding: 3px 10px;
   color:#000;
  } */
.pagination-info p {
    color: #8b909a;
}

.pagination-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.ant-pagination {
    margin-bottom: 0;
    /* Remove bottom margin from pagination */
}

.previous-button {
    border-radius: 20px;
    width: 150px;
    background-color: #1b3a57;
    padding: 10px 20px;
    border: none;
    color: #f7f7f7;
    font-size: 16px;
    transition: background-color 0.3s ease, color 0.3s ease;
    float: left;

}

.previous-button:hover {
    cursor: pointer;
    color: #1b3a57;
    border-radius: 20px;
    padding: 10px 20px;
    background: #d3d3d3;
    transform: scaleX(1);
    transform-origin: 0 50%;
    /* transition: transform 0.475s ease; */

    border: 1px solid #1B3A57;
    color: #1B3A57;
}

@media (min-width: 768px) and (max-width: 992px) {
    .customer-detail-information {
        display: flex;
        flex-direction: column;
        gap: 20px !important;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .personal-information-details h5 {
        padding-left: 30px;
    }

    .profile-name-email {
        margin-bottom: 30px;
        padding-left: 30px;
    }

    .personal-information-data {
        padding-left: 30px;
        margin-bottom: 30px;
    }

    .shipping-details {
        padding-left: 30px;
        margin-bottom: 30px;
    }

    .media-userlist {
        width: 350px;
    }
}

.UsersList-table-row:hover {
    cursor: pointer;
}
.login-page-container {
    background-image: url(../../Assets/images/login-bg.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-admin-logo{
    width: 160px;
    height: auto;
    position: absolute;
    left: 25px;
    top: 20px;
}


.login-details {
    background-color: #fff;
    padding: 50px;
    width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    border-radius: 25px;
}

.login-form {
    display: flex;
    flex-direction: column;
}

.login-form label {
    margin-bottom: 5px;
    color: #2c3e50;
    text-align: left;
    font-weight: 600;
}

.login-form input {
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.form-options {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 20px;
}

.form-options label {
    /* display: flex; */
    align-items: center;
    color: #2c3e50;
}

.form-options input[type="checkbox"] {
    margin-right: 5px;
}

.forgot-password {
    color: #3498db;
    text-decoration: none;
    border: none;
    background-color: #fff;
}

.forgot-password:hover {
    text-decoration: underline;
}

.login-btn {
    background-color: #1b3a57;
    color: #f7f7f7;
    border: none;
    padding: 10px;
    border-radius: 15px;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
}

.login-btn:hover,
.register-btn:hover {
    opacity: 0.9;
}

.password-container {
    position: relative;
    display: flex;
    align-items: center;
}

.email-container {
    position: relative;
    display: flex;
    align-items: center;
}


.password-container input {
    box-sizing: border-box;
    width: -webkit-fill-available;
}

.email-container input {
    box-sizing: border-box;
    width: -webkit-fill-available;
}

.password-toggle {
    margin-left: -40px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
}

.password-toggle .anticon {
    font-size: 20px;
}

.invalid {
    border: 1px solid red !important;
}

.error {
    color: red;
    font-size: 0.875em;
    margin-top: 0.25em;
}

.login-details h4 {
    font-size: 40px;
    color: #1b3a57;
    margin-top: 20px;
}

.forgot-password-container {
    background-image: url(../../Assets/images/for-small.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.forgot-details {
    background-color: #fff;
    padding: 50px;
    width: 500px !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    border-radius: 25px;
}

.verify-code-container {
    background-image: url(../../Assets/images/verify-small.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

.set-password-container {
    background-image: url(../../Assets/images/set-small.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .login-details {
        width: 380px !important;
        padding: 30px;
    }

    .forgot-details {
        width: 390px !important;
        padding: 30px;
        margin: auto;
    }

    .forgot-password-container {
        background-image: none;
        background-color: #1b3a57;
    }

    .verify-code-container {
        background-image: none;
        background-color: #1b3a57;
    }

    .set-password-container {
        background-image: none;
        background-color: #1b3a57;
    }
    .login-admin-logo{
        width: 200px;
        height: auto;
        position: absolute;
        left: 25px;
        top: 20px;
    }

}

.not-accessible-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 180px);
    text-align: center;
}

.admin-dashboard-logout-button{
    background-color: #02528b;
    border: 0;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    outline: 0;
    padding: 0px 20px;
    position: relative;
    text-align: center;
    text-decoration: none;
    transition: all .3s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  .admin-dashboard-logout-button:before{
    background-color: initial;
    background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
    border-radius: 12px;
    content: "";
    height: 50%;
    left: 4%;
    opacity: .5;
    position: absolute;
    top: 0;
    transition: all .3s;
    width: 92%;
  }
  .admin-dashboard-logout-button:hover{
    box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
    transform: scale(1.05);
  }
  :where(.css-dev-only-do-not-override-m4timi).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    background: #01365c;
}
.manage_admins_name_div{
    display: flex;
    align-items: center;
    gap: 20px;
}
.manage_admins_image{
    height: 35px;
    width: 35px;
    border-radius: 50%;
}
.manage_admins_para{
    margin-top: 10px;
    line-height: 10px;
}
.user_role_div{
    display: flex;
}
.user_role_p {
    padding: 5px;
    border-radius: 3px;
}

.admin_role {
    background-color: #DDF6E8; /* Example: red background for Admin */
    color: #00833B;
    font-size: 13px;
    margin: 15px;
}

.manager_role {
    background-color: #C5BD5B; /* Example: blue background for Manager */
    color: #4A4611;
    font-size: 13px;
    margin: 15px;
}
.manageadmins-new-user-label{
    font-size: 14px;
    font-weight: 600;
    color: #162736;
}
.select-role.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector{
    width: 470px;
}
.icon-1-img{
    width: 50px;
    height:50px;
}
.account-row1{
    display: flex;
    gap: 20px;
}
/* .account-card{
    padding: 10px ;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 20px;
} */
.calender-button-account{
    background-color: #fff;
    border: none;
}
.reset-button{
    padding: 8px 16px;
    border-radius: 6px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    background: #ebebeb;
    border:none;
}
.accounts-view-button{
    padding: 8px 16px;
    border-radius: 6px;
    color: #1b3a57;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.1px;
    background: #fff;
    border:1px solid #1b3a57;
}
.view-transaction-modal-div{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 12px;
    border-radius: 10px;
    margin-bottom: 20px;
}
.straight-line{
    border:1px solid #ebebeb;
}
.rotate-icon{
    transform: rotate(180deg);
    transition: transform 0.3s;
}

/* @media (min-width: 1400px) {
    .account-row1{
        display: flex;
        gap: 40px;
    }
    .account-card{
        padding:15px;
    }
} */
@media (max-width: 768px) {
    .account-row1{
        flex-direction: column;
    }
    .account-text{
        flex-direction: column;

    }
    .recent-account {
    flex-direction: column !important;
    
    }
    .select-media{
        flex-direction: column;
       
    }
}
